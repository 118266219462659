<template>
  <v-container>
    <v-tabs v-model="selectedTab">
      <v-tab v-for="item in tabs" :key="item" :to="`/labels/${item}`">
        {{ item }}
      </v-tab>
    </v-tabs>
  </v-container>
</template>

<script>
export default {
  name: "Labels",
  data() {
    return {
      tabs: ["PO", "Address", "Put Away", "Admin"],
      selectedTab: `/labels/${this.typ}`,
    };
  },
  props: {
    typ: {
      type: String,
    },
  },
};
</script>